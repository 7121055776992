import React from 'react'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Posts from '../components/Posts'
import { graphql } from 'gatsby'
import Seo from '../components/SEO'
const IndexPage = ({ data }) => {
  const {
    indexPosts: { nodes: posts },
  } = data

  return (
    <>
      <Seo
        title="Home"
        pathname="/"
        description="In the vast expanse of time and space, we as individuals often find ourselves grappling with the fear of being forgotten, of our existence being rendered insignificant. However, as believers in a higher power, we can take solace in the divine remembrance of God."
      >
        {/* Facebook */}
        <meta property="og:title" content="home" />
        <meta property="og:type" content="home" />
        <meta
          property="og:url"
          content="http://www.godremembers.com/"
        />
        <meta
          property="og:image:url"
          content="https://github.com/Coding-Spec-X/rusty-old-shed/blob/fc663c7ba34ae76c1d1ca868eb2c54f4caee9fae/static/home-page-001.jpg
            "
        />
        <meta property="fb:app_id" content="280770161775606" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Son of God" />
        <meta property="og:site_name" content="God Remembers" />
        <meta
          property="og:description"
          content="In the vast expanse of time and space, we as individuals often find ourselves grappling with the fear of being forgotten, of our existence being rendered insignificant. However, as believers in a higher power, we can take solace in the divine remembrance of God."
        />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@YHWH_Remembers" />
        <meta name="twitter:title" content="home page" />
        <meta
          name="twitter:description"
          content="Our about spot is a wealth of knowledge and learning that can open you up to a whole new way of living."
        />
        <meta
          name="twitter:image"
          content="https://github.com/Coding-Spec-X/rusty-old-shed/blob/fc663c7ba34ae76c1d1ca868eb2c54f4caee9fae/static/home-page-001.jpg"
        />
      </Seo>
      <Layout>
        <Hero showPerson />
        <Posts posts={posts} title="recently published" />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    indexPosts : allMdx(limit: 3, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          date
          slug
          readTime
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
      }
    }
  }
`


export default IndexPage
